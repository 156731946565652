import 'vite/modulepreload-polyfill';
import { gsap } from 'gsap';
import Lenis from '@studio-freight/lenis';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { register } from 'swiper/element/bundle';
import jsonp from 'jsonp'
import './alpine'
import 'swiper/css';

import './components'

gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger);

register();

window.gsap = gsap;
window.jsonp = jsonp;
window.lenis = new Lenis({
  lerp: 0.15,
});

const colors = {
  white: '#FFFFFF',
  black: "#222124",
  gray: '#333333',
  brown: '#463435',
  beige: '#FCF8EF',
  purple: '#AB469A',
  green: '#499D45',
  yellow: '#F3A71D',
  magenta: '#D1185E',
  orange: '#EA5E27',
  red: '#E54E36',
  olive: '#A16D2D',
  blue: '#3AAECE',
};

gsap.ticker.add((time)=>window.lenis.raf(time * 1000))
gsap.ticker.lagSmoothing(0)
window.lenis.on('scroll', ScrollTrigger.update)

window.videoSupported = true
window.theme = { loaded: false }

window.subscribe('test-video:supported', () => {
  window.videoSupported = true;
})

window.subscribe('test-video:unsupported', () => {
  window.videoSupported = false;
})

  window.safeJSONParse = (str) => {
      try {
          return JSON.parse(str);
      } catch (e) {
          return {};
      }
  }

const createWaveEffects = () => {
  const paths = document.querySelectorAll('path.path-anim');

  paths?.forEach(el => {
    const svgEl = el.closest('svg');
    // @ts-expect-error
    const pathTo = el.dataset.pathTo;

    gsap.timeline({
      scrollTrigger: {
        trigger: svgEl,
        start: "top bottom",
        end: "bottom top",
        scrub: 2
      }
    }).to(el, {
      ease: "power1.inOut",
      attr: {
        d: pathTo
      }
    });
  }); 
}

// const createButtonEffects = () => {
//   const buttons = document.querySelectorAll('.btn');

//   const textColor = (btn) => {
//     if (btn.classList.contains('btn--transparent')) return colors['black'];
//     return 'inherit'
//   };

//   [...Array.from(buttons)].forEach((btn) => {

//     const setPosition = (e, ripple) => {
//       const x = e.offsetX;
//       const y = e.offsetY;

//       gsap.set(ripple, {
//         left: `${x}px`,
//         top: `${y}px`,
//       });
//     };

//     const ripple = btn.querySelector('.btn__hover');

//     const handleEnter = (e) => {
//       setPosition(e, e.target.querySelector('.btn__hover'));
//     };

//     const handleExit = (e) => {
//       setPosition(e, e.target.querySelector('.btn__hover'));
//     };

//     btn.addEventListener('mouseenter', handleEnter);
//     btn.addEventListener('mouseout', handleExit);
//   });
// };

const getRandomSign = (): number => {
  return Math.random() < 0.5 ? 1 : -1;
};
const getRandomScale = (): number => {
  return Math.random() < 0.5 ? 0.9 : 1.1;
};

const createParallaxEffects = () => {
  const containers = document.querySelectorAll('.parallax-icons');
  containers.forEach(container => {
    // @ts-ignore
    container.style.opacity = '1';
    const images = container.querySelectorAll('img');
    images.forEach((img) => {

        const amplitude = 20;
        

        gsap.to(img, {
          x: () => Math.random() * amplitude - amplitude / 2,
          scale: getRandomScale(),
          rotate: () => Math.random() * amplitude - amplitude / 2,
          duration: 3.5,
          ease: "sine.inOut",
          repeat: -1,
          yoyo: true
      });
      // Create a parallax effect for each image
      gsap.to(img,{
          y: () => Math.random() * 1000 * getRandomSign(),
          ease: "none",
          scrollTrigger: {
            trigger: container,
            start: "top bottom",
            end: "bottom top",
            scrub: true
          }
      });
    });
  })
};

const createScrollListener = () => {
  const scrollListener = () => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
  }
  window.addEventListener('scroll', scrollListener);
}


window.addEventListener('DOMContentLoaded', () => {
  document.body.classList.add('loaded');
  // createButtonEffects();
  createWaveEffects();
  createParallaxEffects();
  createScrollListener()
  
  console.log(
    '%c Development by Cementworks - https://cementworks.io',
    'display:block;padding:0.5em 1em;font-family:arial;font-size:12px;background:black;color:white;border-radius:2px;'
  );
  console.log(
    '%c Design by Doily Studio',
    'display:block;padding:0.5em 1em;font-family:arial;font-size:12px;background:black;color:white;border-radius:2px;'
  );
  window.theme.loaded = true;
})


